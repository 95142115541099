import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useTranslations } from 'common/services/translations'

import styles from './PublicLayout.module.scss'

export default function ContactSection() {
  const { gettext } = useTranslations()

  return (
    <div className={styles.contact}>
      <Stack alignItems="center" spacing={3} className={styles.contactContent}>
        <Typography variant="h4" color="primary.dark" fontWeight={700}>
          {gettext('Have any questions?')}
        </Typography>
        <Typography color="primary.dark" textAlign="center">
          {gettext(
            'We are here to help! Whether you have questions about a research study, need support, or want to learn more about how Elixir could benefit you, feel free to reach out to us.'
          )}
        </Typography>
        <Button href="/contact" variant="outlined" size="large">
          {gettext('Contact Us')}
        </Button>
      </Stack>
    </div>
  )
}
