import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Field, Formik, Form, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { InviteType } from 'types'
import useFormSubmit from 'common/hooks/useFormSubmit'
import { interpolate, useTranslations } from 'common/services/translations'

import getValidationSchema from './validationSchema'

type CreateValues = {
  password: string
  password_confirm: string
  signature?: string
}

const initialValues: CreateValues = {
  password: '',
  password_confirm: '',
}

export default function AcceptInvite() {
  const { gettext } = useTranslations()
  const { signature } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { data, error, isLoading } = useQuery<InviteType>({
    queryKey: [`invitations/${signature}`],
    enabled: Boolean(signature),
  })

  const validationSchema = useMemo(
    () => getValidationSchema(gettext),
    [gettext]
  )
  const { generalError, submitForm } =
    useFormSubmit<CreateValues>('invitations/accept')

  const handleSubmit = useCallback(
    (data: CreateValues, actions: FormikHelpers<CreateValues>) => {
      const onSuccess = () => {
        enqueueSnackbar(gettext('Invite was accepted successfully'), {
          variant: 'success',
        })
        navigate('/auth/login')
      }

      return submitForm({
        data: {
          ...data,
          signature,
        },
        actions,
        onSuccess,
      })
    },
    [enqueueSnackbar, gettext, navigate, submitForm, signature]
  )

  if (isLoading) {
    return (
      <Box
        height="300px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (error || !data) {
    return (
      <Typography variant="h5" fontWeight={600}>
        {gettext('Invalid signature')}
      </Typography>
    )
  }

  if (data.is_expired) {
    return (
      <>
        <Typography variant="h5" fontWeight={600} mb={2}>
          {gettext('Email Verification Link Expired')}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            a: {
              color: 'inherit',
              textDecoration: 'underline',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: interpolate(
              gettext(
                'Looks like the verification link has expired. %s to resend.'
              ),
              [
                `<a href="https://elixir.ai/contact/" target="_blank">${gettext('Contact Us')}</a>`,
              ]
            ),
          }}
        />
      </>
    )
  }

  return (
    <>
      {generalError && (
        <Alert severity="error" color="error" sx={{ mb: 2 }}>
          <AlertTitle>{gettext('Error')}</AlertTitle>
          {generalError}
        </Alert>
      )}
      <Typography variant="h5" fontWeight={600} mb={2}>
        {interpolate(gettext('Welcome %s,'), [data.first_name])}
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={4}>
        {interpolate(gettext('Create password to join the %s team.'), [
          data.hospital.title,
        ])}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component={TextField}
              name="password"
              type="password"
              label={gettext('Create Password')}
              placeholder={gettext('Your password')}
              helperText={gettext('Minimum 8 characters')}
              sx={{ width: '100%', mb: 4 }}
              InputLabelProps={{ shrink: true }}
            />
            <Field
              component={TextField}
              name="password_confirm"
              type="password"
              label={gettext('Confirm Password')}
              placeholder={gettext('Confirm password')}
              sx={{ width: '100%', mb: 3 }}
              InputLabelProps={{ shrink: true }}
            />
            <Typography
              component="p"
              variant="caption"
              color="text.secondary"
              mb={3}
              sx={{
                a: {
                  color: 'inherit',
                  textDecoration: 'underline',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: interpolate(
                  gettext(
                    'By continuing, you agree that we create an account for you (unless already created), and accept our %s and %s.'
                  ),
                  [
                    `<a href="https://elixir.ai/terms-and-conditions/" target="_blank">${gettext('Terms and Conditions')}</a>`,
                    `<a href="https://elixir.ai/privacy-policy/" target="_blank">${gettext('Privacy Policy')}</a>`,
                  ]
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {gettext('Continue')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
