import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useTranslations } from 'common/services/translations'

import styles from './Contact.module.scss'

export default function Contact() {
  const { gettext } = useTranslations()

  return (
    <div className={styles.wrapper}>
      <Stack spacing={4} className={styles.content}>
        <Typography
          variant="h3"
          color="primary.dark"
          fontWeight={800}
          textAlign="center"
        >
          {gettext('Contact Us')}
        </Typography>
        <Typography variant="body2" color="primary.dark">
          {gettext(
            "We are here to help! If you have any questions, need support, or want more information about our studies, please don't hesitate to contact us."
          )}
        </Typography>
        <Typography variant="body2" color="primary.dark">
          {gettext(
            'If your question is about a specific study, simply click the link below to email our study team. A member of our study team will contact you as soon as possible.'
          )}
        </Typography>
        <Typography variant="body2" color="primary.dark">
          {gettext(
            'If your question is about a study, to ensure we can assist you as effectively as possible please include:'
          )}
        </Typography>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Typography variant="body2" color="primary.dark">
              {gettext('Your name')}
            </Typography>
          </li>
          <li>
            <Typography variant="body2" color="primary.dark">
              {gettext("Your child's name")}
            </Typography>
          </li>
          <li>
            <Typography variant="body2" color="primary.dark">
              {gettext('Your mobile phone number')}
            </Typography>
          </li>
          <li>
            <Typography variant="body2" color="primary.dark">
              {gettext('The research study that you are asking about')}
            </Typography>
          </li>
          <li>
            <Typography variant="body2" color="primary.dark">
              {gettext('A brief description of your questions or concerns')}
            </Typography>
          </li>
        </ul>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 600, p: 4 }}>
            <Stack spacing={1} alignItems="center">
              <Typography
                variant="subtitle1"
                color="primary.dark"
                fontWeight={600}
              >
                {gettext('Email Us')}
              </Typography>
              <Typography variant="body2" color="primary.main">
                info@elixir.ai
              </Typography>
              <Button
                href="mailto:info@elixir.ai"
                variant="contained"
                size="large"
              >
                {gettext('Click here to send an Email')}
              </Button>
            </Stack>
          </Paper>
        </Box>
        <Typography variant="body2" color="primary.dark" textAlign="center">
          {gettext('We look forward to hearing from you.')}
        </Typography>
      </Stack>
    </div>
  )
}
