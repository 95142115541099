import * as Yup from 'yup'

import { PHONE_REGEX, POSTAL_REGEX } from 'common/constants/common'

export default function getValidationSchema(
  gettext: (text: string) => string,
  isHospitalEnabled: boolean
) {
  const requiredMessage = gettext('Required field')

  return Yup.object().shape({
    hospital: Yup.string().when({
      is: () => isHospitalEnabled,
      then: (schema) => schema.required(requiredMessage),
      otherwise: (schema) => schema.notRequired(),
    }),
    hospital_if_not_listed: Yup.string(),
    first_name: Yup.string().required(requiredMessage),
    last_name: Yup.string().required(requiredMessage),
    date_of_birth: Yup.string().required(requiredMessage),
    nhs: Yup.string()
      .required(requiredMessage)
      .matches(/^\d{3}\s?\d{3}\s?\d{4}$/, gettext('Invalid NHS number')),
    ethnicity: Yup.string().required(requiredMessage),
    ethnicity_description: Yup.string().when('ethnicity', {
      is: (ethnicity: string | null) => ethnicity?.includes('other'),
      then: (schema) => schema.required(requiredMessage),
      otherwise: (schema) => schema,
    }),
    guardian_first_name: Yup.string().required(requiredMessage),
    guardian_last_name: Yup.string().required(requiredMessage),
    guardian_relationship: Yup.string().required(requiredMessage),
    contact_number: Yup.string()
      .required(requiredMessage)
      .matches(PHONE_REGEX, gettext('Invalid phone number')),
    email: Yup.string()
      .email(gettext('Email is wrong'))
      .required(requiredMessage),
    postal_code: Yup.string()
      .required(requiredMessage)
      .matches(POSTAL_REGEX, gettext('Invalid postal code')),
  })
}
